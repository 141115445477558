import { flightType } from "./flightType/en";
import { flightClass } from "./flightClass/en";
import { flightSortTypeForConsultation } from "./flightSortType/en";
import { flightReservedSeatRequestType } from "./flightReservedSeatRequestType/en";
import { hotelSortTypeForConsultation } from "./hotelSortType/en";
import { hotelMealType } from "./hotelMealType/en";
import { hotelViewType } from "./hotelViewType/en";
import { hotelBedSizeType } from "./hotelBedSizeType/en";
import { searchMealType } from "./searchMealType/en";
import { companionType } from "./companionType/en";
import { passengerType } from "./passengerType/en";
import { flightFreeBaggageType } from "./flightFreeBaggageType/en";
import { cityCode } from "./cityCode/en";
import { postStatus } from "./postStatus/en";
import { searchType } from "./searchType/en";
import { seo } from "./seo/en";
import { en as platformType } from "./platformType/ja.yml";

export const constantEn: any = {
  flightType,
  flightClass,
  flightSortTypeForConsultation,
  flightReservedSeatRequestType,
  hotelSortTypeForConsultation,
  hotelMealType,
  hotelViewType,
  hotelBedSizeType,
  searchMealType,
  companionType,
  passengerType,
  flightFreeBaggageType,
  cityCode,
  postStatus,
  searchType,
  seo,
  platformType,
};
